import React from "react";

import "./Header.css";

const Header = () => {
  return (
    <header
      className="home"
      id="home"
      style={{
        backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      <div className="home__titles">
        <h1 className="home__title--1">Joep Janssen</h1>
        <h2 className="home__title--2">Full-stack developer</h2>
      </div>
    </header>
  );
};

export default Header;

import React from "react";

import "./Experience.css";

const Experience = () => {
  const skills = [
    { title: "HTML", score: 9 },
    { title: "CSS", score: 8 },
    { title: "Javascript", score: 7 },
    { title: "PHP", score: 7 },
    { title: "SQL", score: 6 },
    { title: "Wordpress", score: 4 },
    { title: "React", score: 5 },
    { title: "Laravel", score: 2 },
    { title: "AngularJS", score: 4 },
    { title: "Java", score: 2 },
    { title: "C#", score: 3 },
  ];

  const renderSkillBars = () => {
    return skills.map((skillInfo, skillIndex) => (
      <SkillBar key={skillIndex} skillInfo={skillInfo} />
    ));
  };

  return (
    <section className="experience" id="experience">
      <h2 className="experience__title">Vaardigheden</h2>
      <div className="experience__skillbars">{renderSkillBars()}</div>
    </section>
  );
};

const SkillBar = (props) => {
  const { skillInfo } = props;

  const { title, shortCode, score } = skillInfo;

  return (
    <section className={"experience__" + shortCode}>
      <p className="experience__section--title">{title}</p>
      <div className="experience__skillbar--bar">
        <div
          className={
            "experience__skillbar--bar-score " +
            (score < 5 ? "experience__skillbar--bar-inexperienced" : "")
          }
        ></div>
        <div
          className="experience__skillbar--bar-hidden-score"
          style={{ width: (10 - score) * 10 + "%" }}
        ></div>
      </div>
      <section className="experience__skillbar">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </section>
    </section>
  );
};

export default Experience;

import React from "react";

import "./Internships.css";

const Internships = () => {
  const internships = [
    {
      company: "MMC IT Solutions",
      location: "Lelystad",
      startDate: "Februari 2020",
      endDate: "Juli 2020",
      URL: "https://mmc-itsolutions.nl/",
      tasks: [
        "Gewerkt aan een applicatie die scores bijhoudt tijdens het darten.",
      ],
    },
    {
      company: "Daylinq",
      location: "Almere",
      startDate: "September 2020",
      endDate: "Februari 2021",
      URL: "https://www.daylinq.nl/",
      tasks: [
        "Wordpress websites aangepast",
        "Gewerkt aan een dakraam calculator",
      ],
    },
    {
      company: "PTI",
      location: "Woerden",
      startDate: "September 2023",
      endDate: "Januari 2023",
      URL: "https://pti.nl/",
      tasks: ["Applicatie gemaakt met React."],
    },
    {
      company: "Posonas",
      location: "Naarden",
      startDate: "Maart 2023",
      endDate: "Juli 2023",
      URL: "https://posonas.nl/",
      tasks: ["Basis framework voor hun webshops.", "Gemaakt met React."],
    },
  ];

  const renderInternships = () => {
    return internships.map((internship, internshipIndex) => (
      <Internship key={internshipIndex} internship={internship} />
    ));
  };

  return (
    <section className="internships" id="internships">
      <h2 className="internships__title">Stages</h2>
      <div className="internships__list">{renderInternships()}</div>
    </section>
  );
};

const Internship = (props) => {
  const { internship } = props;
  const { company, location, startDate, endDate, URL, tasks } = internship;

  return (
    <div className="internships__intership">
      <h2 className="internships__intership--name">{company}</h2>
      <p className="internships__intership--place">
        <b>Plaats:</b> {location}
      </p>
      <p className="internships__intership--intership_period">
        <b>Stageperiode:</b> {startDate} - {endDate}
      </p>
      <a className="internships__intership--url" href={URL}>
        Link naar bedrijf website
      </a>
      <h3 className="internships__intership--tasks-title">
        Taken die ik gedaan heb
      </h3>
      <ul className="internships__intership--tasks">
        {tasks.map((task, taskIndex) => (
          <li key={taskIndex}>{task}</li>
        ))}
      </ul>
    </div>
  );
};

export default Internships;

import React, { useRef } from "react";

import "./ModalWindow.css";

const ModalWindow = (props) => {
  const { children, modalWindowStatus, setModalWindowStatus } = props;

  const clickModalRef = useRef(false);

  const checkCloseModalWindow = () => {
    if (clickModalRef.current === false) {
      setModalWindowStatus(false);
    } else {
      clickModalRef.current = false;
    }
  };

  return (
    <div
      className="modalWindow__background"
      style={{ display: modalWindowStatus ? "grid" : "none" }}
      onClick={checkCloseModalWindow}
    >
      <div
        className="modalWindow__create"
        onClick={() => {
          clickModalRef.current = true;
        }}
      >
        <div>
          <button
            className="modalWindow__cross"
            onClick={() => setModalWindowStatus(false)}
          >
            &#x00D7;
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default ModalWindow;

import "./App.css";

import Nav from "./sections/Nav/Nav";
import Header from "./sections/Header/Header";
import AboutMe from "./sections/AboutMe/AboutMe";
import Experience from "./sections/Experience/Experience";
import MyWork from "./sections/MyWork/MyWork";
import Contact from "./sections/Contact/Contact";
import Internships from "./sections/Internships/Internships";

function App() {
  return (
    <div className="App">
      <Nav />

      <Header />

      <AboutMe />

      <Internships />

      <Experience />

      <MyWork />

      <Contact />
    </div>
  );
}

export default App;

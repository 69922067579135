import React from "react";

import "./Contact.css";

const Contact = () => {
  return (
    <section className="contact" id="contact">
      <h2 className="contact__title">Contact</h2>
      <p className="contact__text">
        Voor vragen of opmerkingen kun je contact opnemen
      </p>
      <div className="contact__buttons">
        <input
          type="button"
          value="Informatie"
          className="knop experience__download--link"
        />
        <section className="modalWindow contact__informatie">
          <div>
            <h2>Contact Informatie</h2>
          </div>
          <div className="contact__informatie--spans">
            <span>
              <img
                className="contact__informatie--icon"
                src="images/persoonIcon.png"
                alt="icon persoon"
              />
              Joep Janssen
            </span>
            <span>
              <img
                className="contact__informatie--icon"
                src="images/locatieIcon.png"
                alt="icon locatie"
              />
              <a
                className="contact__informatie--link"
                href="https://goo.gl/maps/4eTLzfmErg9xEh3EA"
                target="_blank"
                rel="noreferrer"
              >
                Almere, Nederland
              </a>
            </span>
            <span>
              <img
                className="contact__informatie--icon"
                src="images/mailIcon.png"
                alt="icon mail"
              />
              <a
                className="contact__informatie--link"
                href="mailto::joep.dragon13@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                joep.dragon13@gmail.com
              </a>
            </span>
            <span>
              <img
                className="contact__informatie--icon"
                src="images/telefoonIcon.png"
                alt="icon telefoon"
              />
              <a
                className="contact__informatie--link"
                href="tel:0031657771599"
                target="_blank"
                rel="noreferrer"
              >
                +316 57771599
              </a>
            </span>
          </div>
        </section>
        <input
          type="button"
          value="Formulier"
          className="knop experience__download--link"
        />
        <section className="modalWindow contact__formulier">
          <div>
            <h2>Contact formulier</h2>
          </div>
          <div>
            <p>
              Neem contact op via dit formelier of stuur een email
              <br />
              naar
              <a href="mailto::joep.dragon13@gmail.com">
                joep.dragon13@gmail.com
              </a>
            </p>
            <form action="php/mail.php" method="GET">
              <input
                className="contact__formulier--input"
                name="naam"
                type="text"
                placeholder="Naam"
                required
              />
              <input
                className="contact__formulier--input"
                name="email"
                type="email"
                placeholder="Email"
                required
              />
              <br />
              <textarea
                className="contact__formulier--textarea"
                rows="7"
                cols="46"
                name="bericht"
                placeholder="Bericht"
                required
              ></textarea>
              <br />
              <input
                className="contact__formulier--submit"
                type="submit"
                value="Verstuur"
              />
            </form>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Contact;

import React from "react";

import "./Nav.css";

const Nav = () => {
  return (
    <nav className="nav">
      <ul className="nav__list">
        <li>
          <a href="#home" className="nav__list--item">
            Home
          </a>
        </li>
        <li>
          <a href="#aboutMe" className="nav__list--item">
            Over mij
          </a>
        </li>
        <li>
          <a href="#experience" className="nav__list--item">
            Vaardigheden
          </a>
        </li>
        <li>
          <a href="#internships" className="nav__list--item">
            Stages
          </a>
        </li>
        <li>
          <a href="#myWork" className="nav__list--item">
            Mijn werk
          </a>
        </li>
        <li>
          <a href="#contact" className="nav__list--item">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;

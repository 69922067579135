import React, { useState } from "react";

import ModalWindow from "../../ModalWindow/ModalWindow";

import "./MyWork.css";

const MyWork = () => {
  const workItems = [
    {
      title: "Dakraam calculator",
      image: "images/myworks/dakraamCalculator.png",
      descriptions: {
        small:
          "Toen ik bij Daylinq stage had heb ik gewerkt aan dit project voor Wagnaar. Dit project is gemaakt met AngularJS.",
        large:
          "Deze website faciliteert gebruikers op een gebruiksvriendelijke wijze bij het berekenen van de kosten voor het plaatsen of vervangen van een dakraam.\n " +
          "Ik heb dit project overgenomen van een voorganger en heb diverse aanpassingen doorgevoerd, waardoor het project nu sterk met mijn inbreng verweven is. " +
          "In de beginfase ondervond ik uitdagingen bij het werken aan dit project, voornamelijk omdat dit mijn eerste ervaring met AngularJS betrof. " +
          "Niettemin, nadat ik het onder de knie had, heb ik verschillende functionaliteiten toegevoegd.\n " +
          "Een bijzondere functie waar ik trots op ben, is de mogelijkheid van de website om de gemaakte keuzes te onthouden middels een unieke ID die wordt opgeslagen in de URL van de website.",
      },
      gitHubLink: false,
      websiteLink: "https://www.dakraamexpertwagenaar.nl/calculators/dakraam/",
    },
    {
      title: "Lichtkoepl calculator",
      image: "images/myworks/lichtkoepelCalculator.png",
      descriptions: {
        small:
          "Toen ik bij Daylinq stage had heb ik gewerkt aan dit project voor Wagnaar. Dit project is gemaakt met AngularJS.",
        large:
          "Deze website is grotendeels gebaseerd op het Dakraam calculator-project, verrijkt met diverse opties. " +
          "In het begin ondervond ik uitdagingen met dit project, met name het feit dat het toevoegen van nieuwe functionaliteiten aan één calculator vereiste dat de code ook voor de andere calculator werd aangepast. \n" +
          "Een aspect waar ik met trots op terugkijk binnen dit project, is de implementatie van dynamische calculators. Dit betekent dat ik slechts op één locatie aanpassingen hoef door te voeren om nieuwe functionaliteiten toe te voegen.",
      },
      gitHubLink: false,
      websiteLink:
        "https://www.dakraamexpertwagenaar.nl/calculators/lichtkoepel/",
    },
  ];

  const renderMyWorks = () => {
    return workItems.map((workItem, workItemIndex) => (
      <MyWorkItem key={workItemIndex} workItem={workItem} />
    ));
  };

  return (
    <section className="myWork" id="myWork">
      <h2 className="myWork__title">Mijn werk</h2>

      <div className="myWork--grid">{renderMyWorks()}</div>
    </section>
  );
};

const MyWorkItem = (props) => {
  const { workItem } = props;
  const { title, image, descriptions, gitHubLink, websiteLink } = workItem;

  const [modalWindowStatus, setModalWindowStatus] = useState(false);

  return (
    <section className="myWork__project">
      <section className="myWork__project--inside">
        <h3 className="myWork__project--inside_title">{title}</h3>
        <img src={image} className="myWork__project--inside_img" />
        <p className="myWork__project--inside_text">{descriptions.small}</p>
        <div className="myWork__project--inside_button_margin">
          <input
            type="button"
            value="Meer Informatie"
            className="knop myWork__project--inside_button"
            onClick={() => setModalWindowStatus(true)}
          />
        </div>
        <ModalWindow
          modalWindowStatus={modalWindowStatus}
          setModalWindowStatus={setModalWindowStatus}
        >
          <div>
            <h2>{title}</h2>
          </div>
          <div className="myWork__project--modal-grid">
            <img src={image} className="myWork__project--image" />
            <p className="myWork__project--text">
              {descriptions.large.split("\n").map((line, index) => {
                return (
                  <React.Fragment key={index}>
                    {line} <br />
                    <br />
                  </React.Fragment>
                );
              })}
            </p>
            <section className="myWork__project--icons">
              {gitHubLink === false ?? (
                <a href={gitHubLink} target="_blank" rel="noreferrer">
                  <abbr title="Naar GitHub">
                    <img src="images/github.png" alt="github" />
                  </abbr>
                </a>
              )}
              <a
                href={websiteLink}
                className="website"
                target="_blank"
                rel="noreferrer"
              >
                <abbr title="Naar de website">
                  <img src="images/website.png" alt="website" />
                </abbr>
              </a>
            </section>
          </div>
        </ModalWindow>
      </section>
    </section>
  );
};

export default MyWork;

import React from "react";

import "./AboutMe.css";

const AboutMe = () => {
  return (
    <section className="aboutMe" id="aboutMe">
      <h2 className="aboutMe__title">Over mij</h2>
      <div className="aboutMe__texts">
        <p className="aboutMe__text">
          Mijn naam is Joep Janssen, geboren in 2002 en ik woon in Almere. Ik
          ben een full stack developer. Na het VMBO-TL heb ik in 2021 de
          opleiding Mediadeveloper op het{" "}
          <a
            href="https://www.ma-web.nl/opleidingen/opleidingsoverzicht/softwaredeveloper/"
            target="_black"
          >
            Mediacollege Amsterdam
          </a>{" "}
          afgerond. In 2024 heb ik mijn Associate Degree Software devoloper op
          het{" "}
          <a
            href="https://www.windesheim.nl/opleidingen/voltijd/ad/software-development"
            target="_black"
          >
            Windesheim van Almere
          </a>{" "}
          met succes afgerond.
        </p>

        <p className="aboutMe__text">
          Ik ben een programmeur omdat ik altijd wilde weten hoe het is om apps,
          games en websites zelf te maken.
        </p>

        <p className="aboutMe__text">
          Ik ben een full stack developer, omdat ik met front- en back-end
          development om kan gaan. Met HTML,CSS en JavaScript kan ik beter
          omgaan dan met PHP dus ik ben beter in front- dan back-end
          development.
        </p>

        <p className="aboutMe__text">
          Mijn hobby's zijn gamen en programmeren. Ik vind het leuk om met
          familie bordspellen te gaan doen en in mijn vrije tijd speel ik
          spelletjes op mijn computer. In mijn eigen tijd werk ik aan een{" "}
          <a href="https://jrjweb.nl/online_game/" target="_black">
            RPG game
          </a>
          .
        </p>

        <div className="aboutMe__download">
          <a
            className="aboutMe__download--link"
            href="/images/cv.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Bekijk CV
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
